
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&display=swap');

.pac-container.pac-logo::after{
  display: none !important;
}

.pac-icon	{
  display: none !important;
}

.pac-item	{
  padding: 4px 10px;
  cursor: pointer;
}

.scp-item{
  font-family: 'Open Sans', sans-serif;
}

.full-star, .half-star,.empty-star{
  background-size: 14px 14px;
  height: 14px;
  width: 14px;
  vertical-align: top;
  margin-top: 3px;
  display: inline-block;
}

.full-star{
  background-image: url('../images/star_full.png');
}
.half-star{
  background-image: url('../images/star_half.png');
}
.empty-star{
  background-image: url('../images/star_empty.png');
}

.search-input-before{
  background-image: url(data:image/svg+xml;base64,PHN2ZyByb2xlPSJpbWciIGFyaWEtaGlkZGVuPSJ0cnVlIiB3aWR0aD0iMTciIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNyAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjM2MiA3LjAwNTQyQzEzLjM2MiAxMC41MTYxIDEwLjUxNjEgMTMuMzYyIDcuMDA1NDIgMTMuMzYyQzMuNDk0NzUgMTMuMzYyIDAuNjQ4Nzk2IDEwLjUxNjEgMC42NDg3OTYgNy4wMDU0MkMwLjY0ODc5NiAzLjQ5NDc1IDMuNDk0NzUgMC42NDg3OTYgNy4wMDU0MiAwLjY0ODc5NkMxMC41MTYxIDAuNjQ4Nzk2IDEzLjM2MiAzLjQ5NDc1IDEzLjM2MiA3LjAwNTQyWiIgc3Ryb2tlPSIjNjA2MDYwIiBzdHJva2Utd2lkdGg9IjEuMjk3NTkiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTAuNzM3MyAxMS44MjA5TDExLjgyMTEgMTAuNzM3M0wxNi4wMDE0IDE0LjkxNjlMMTUuNDQxOSAxNS40NzYzQzE1LjE1MjMgMTUuNzY1OCAxNC42ODI5IDE1Ljc2NTggMTQuMzkzMyAxNS40NzYzTDEwLjczNzMgMTEuODIwOVoiIGZpbGw9IiM2MDYwNjAiLz4KPC9zdmc+Cg==);
}
